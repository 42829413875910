import './Dashboard.css';
import { FaChevronUp } from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaRegUser } from "react-icons/fa";
import React, { useRef, useState } from 'react';
import Icon from '@mdi/react';
import { mdiPoll } from '@mdi/js';
import { mdiTrendingUp } from '@mdi/js';
import Swiper from './../../../Components/Swiper/Swiper';
import Lyustrimg1 from './../../../Assets/Lyustr/pngwing.com.png';
import Lyustrimg2 from './../../../Assets/Lyustr/pngwing.com (1).png';
import Lyustrimg3 from './../../../Assets/Lyustr/pngwing(2).com.png';
import { BiMobileAlt } from "react-icons/bi";
import { HiMiniComputerDesktop } from "react-icons/hi2";
import ApexChart from './../../../Components/Charts/Line/Line';
import { FaPlus } from "react-icons/fa6";
import { mdiChandelier } from '@mdi/js';
import useimg from '../../../Assets/User_img.jpg'


export default function Dashboard() {
    const [selectedContent, setSelectedContent] = useState(1); // Инициализация значением 1

    const handleProdBoxClick = (index) => {
        setSelectedContent(index);
    };

    const dates = [
        { x: '2024-01-01', y: 1000000 },
        { x: '2024-01-02', y: 1100000 },
        // more data points
    ];

    const [isActive, SetAcrive] = useState(false)
    const [isActiveClass, setIsActiveClass] = useState(false);

    const toggleActive = () => {
        setIsActiveClass(!isActiveClass);
    };

    const dotClick = () => {
        SetAcrive(!isActive);
    };

    const slides = [
        <div className='SlideContBox' >
            <div className="SliderBoxLeft">
                <div className="SliderBoxLeftUp">
                    <h4>Weekly sales</h4>
                    <p>Total $23.5k Earning <span>+62%</span></p>
                </div>
                <div className="SliderBoxLeftDuwn">
                    <div className="SliderBoxLeftDuwnTitle">
                        <h4>Fashion</h4>
                    </div>
                    <div className="SliderBoxLeftDuwnBox">
                        <div className="SliderBoxLeftDuwnBoxLeft">
                            <div className="SliderBoxLeftContentBox">
                                <span>16</span>
                                <p>TV's</p>
                            </div>
                            <div className="SliderBoxLeftContentBox">
                                <span>9</span>
                                <p>Cameras</p>
                            </div>
                        </div>
                        <div className="SliderBoxLeftDuwnBoxRight">
                            <div className="SliderBoxLeftContentBox">
                                <span>40</span>
                                <p>Spikers</p>
                            </div>
                            <div className="SliderBoxLeftContentBox">
                                <span>18</span>
                                <p>Consoles</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="SliderBoxRight">
                <div className="SliderBoxRightImg">
                    <img src={Lyustrimg1} alt="" />
                </div>
            </div>
        </div>,
        <div className='SlideContBox' >
            <div className="SliderBoxLeft">
                <div className="SliderBoxLeftUp">
                    <h4>Weekly sales</h4>
                    <p>Total $23.5k Earning <span>+62%</span></p>
                </div>
                <div className="SliderBoxLeftDuwn">
                    <div className="SliderBoxLeftDuwnTitle">
                        <h4>Fashion</h4>
                    </div>
                    <div className="SliderBoxLeftDuwnBox">
                        <div className="SliderBoxLeftDuwnBoxLeft">
                            <div className="SliderBoxLeftContentBox">
                                <span>16</span>
                                <p>TV's</p>
                            </div>
                            <div className="SliderBoxLeftContentBox">
                                <span>9</span>
                                <p>Cameras</p>
                            </div>
                        </div>
                        <div className="SliderBoxLeftDuwnBoxRight">
                            <div className="SliderBoxLeftContentBox">
                                <span>40</span>
                                <p>Spikers</p>
                            </div>
                            <div className="SliderBoxLeftContentBox">
                                <span>18</span>
                                <p>Consoles</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="SliderBoxRight">
                <div className="SliderBoxRightImg">
                    <img src={Lyustrimg2} alt="" />
                </div>
            </div>
        </div>,
        <div className='SlideContBox' >
            <div className="SliderBoxLeft">
                <div className="SliderBoxLeftUp">
                    <h4>Weekly sales</h4>
                    <p>Total $23.5k Earning <span>+62%</span></p>
                </div>
                <div className="SliderBoxLeftDuwn">
                    <div className="SliderBoxLeftDuwnTitle">
                        <h4>Fashion</h4>
                    </div>
                    <div className="SliderBoxLeftDuwnBox">
                        <div className="SliderBoxLeftDuwnBoxLeft">
                            <div className="SliderBoxLeftContentBox">
                                <span>16</span>
                                <p>TV's</p>
                            </div>
                            <div className="SliderBoxLeftContentBox">
                                <span>9</span>
                                <p>Cameras</p>
                            </div>
                        </div>
                        <div className="SliderBoxLeftDuwnBoxRight">
                            <div className="SliderBoxLeftContentBox">
                                <span>40</span>
                                <p>Spikers</p>
                            </div>
                            <div className="SliderBoxLeftContentBox">
                                <span>18</span>
                                <p>Consoles</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="SliderBoxRight">
                <div className="SliderBoxRightImg">
                    <img src={Lyustrimg3} alt="" />
                </div>
            </div>
        </div>,
    ];

    return (
        <div className="Dashboard">
            <div className="SalesContainer">
                <div className="SalesBox">
                    <div className="SalesBoxUp">
                        <div className="SalesBoxUpLeft">
                            <h1>Sales Overview</h1>
                            <p>Total 42.5k Sales <span>+18% <div><FaChevronUp /></div></span></p>
                        </div>
                        <div className="SalesBoxRight">
                            <button className={`Dotbutton ${isActive ? 'active' : ''}`} onClick={dotClick}><BsThreeDotsVertical />
                                <div className="DotButtonDrop">
                                    <button>Refresh</button>
                                    <button>Share</button>
                                    <button>Update</button>
                                </div>
                            </button>
                        </div>
                    </div>
                    <div className="SalesBoxDuwn">
                        <div className="Custumers">
                            <div className="CustumerLeft">
                                <FaRegUser />
                            </div>
                            <div className="CustumerRight">
                                <h1>8,458</h1>
                                <p>New Customers</p>
                            </div>
                        </div>
                        <div className="Total">
                            <div className="TotalLeft">
                                <Icon path={mdiPoll} size={1} />
                            </div>
                            <div className="TotalRight">
                                <h1>$28.5k</h1>
                                <p>Total Profit</p>
                            </div>
                        </div>
                        <div className="Transactions">
                            <div className="TransactionsLeft">
                                <Icon path={mdiTrendingUp} size={1} />
                            </div>
                            <div className="TransactionsRight">
                                <h1>2,450k</h1>
                                <p>New Transactions</p>
                            </div>
                        </div>
                        <div className="Clean">
                            <div className="CleanLeft">
                                <Icon path={mdiPoll} size={1} />
                            </div>
                            <div className="CleanRight">
                                <h1>$8.45k</h1>
                                <p>Clean Surf</p>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="Raiting">
                    <div className="RaitingUp">
                        <h1>Raiting</h1>
                        <span>03.07.2024</span>
                    </div>
                    <div className="RaitingDuwn">
                        <div className="RaitingUserBox">
                            <h1>
                                8.84k
                            </h1>
                            <span>+15.6%</span>
                        </div>
                    </div>
                </div>
                <div className="Sessions">
                    <div className="SessionUp">
                        <h1>Sessions</h1>
                        <span>Last Month</span>
                    </div>
                    <div className="SessionDuwn">
                        <div className="SessionUserBox">
                            <h1>
                                12.2k
                            </h1>
                            <span>-25.5%</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="WeeklyContainer">
                <div className="WeeklySlider">
                    <Swiper slides={slides} autoPlayInterval={3000} />
                </div>
                <div className="Visit">
                    <div className="VisitTitleBox">
                        <div className="VisitTitleBoxOne">
                            <h4>Total Visits</h4>
                            <span>+18.4%<p></p></span>
                        </div>
                        <div className="VisitPodTitle">
                            <h1>$42.5k</h1>
                        </div>
                    </div>
                    <div className="VisitThreeBox">
                        <div className="VisitThreeOne">
                            <div className="VisitThreeOneup">
                                <span><BiMobileAlt /></span>
                                <p>Mobile</p>
                            </div>
                            <div className="VisitThreeOneDuwn">
                                <h4>23.5%</h4>
                                <p>2,890</p>
                            </div>
                        </div>
                        <div className="VisitThreeBoxTwo">
                            <span></span>
                            <div className="VisitThreeBoxTwoT">
                                <p>vs</p>
                            </div>
                        </div>
                        <div className="VisitThreeBoxThree">
                            <div className="VisitThreeBoxUp">
                                <span><HiMiniComputerDesktop /></span>
                                <p>Desktop</p>
                            </div>
                            <div className="VisitThreeBoxDown">
                                <h4>76.5%</h4>
                                <p>22,465</p>
                            </div>
                        </div>
                    </div>
                    <div className="VisitThreeBoxUnder">
                        <span></span>
                    </div>
                </div>
                <div className="MonthSales">
                    <div className="MonthSalesTitle">
                        <h4>Sales This Month</h4>
                    </div>
                    <div className="MonthSalesTitlePod">
                        <h4>Total Sales This Month</h4>
                        <p>$28,450</p>
                    </div>
                    <div className="MonthSalesPng">
                        <ApexChart dates={dates} />
                    </div>
                </div>
            </div>
            <div className="LstPizdos">
                <div className="ProdTovar">
                    <div className="ProdTitle">
                        <div className="ProdTitleLeft">
                            <h4>Top Referral Sources</h4>
                            <p>82% Activity Growth</p>
                        </div>
                        <div className="ProdTitleRight">
                            <button className={`icon-button ${isActiveClass ? 'active' : ''}`} onClick={toggleActive}>
                                <BsThreeDotsVertical />
                                <div className="PeodTitleRightDrop">
                                    <button>Viev More</button>
                                    <button>Delete</button>
                                </div>
                            </button>
                        </div>
                    </div>
                    <div className="ProdName">
                        <div className="ProdButton">
                            <div className="ProdBox" onClick={() => handleProdBoxClick(1)}>
                                <span><Icon path={mdiChandelier} size={1} /></span>
                            </div>
                            <div className="ProdBox" onClick={() => handleProdBoxClick(2)}>
                                <span><BiMobileAlt /></span>
                            </div>
                            <div className="ProdBox" onClick={() => handleProdBoxClick(3)}>
                                <span><BiMobileAlt /></span>
                            </div>
                            <div className="ProdBox" onClick={() => handleProdBoxClick(4)}>
                                <span><FaPlus /></span>
                            </div>
                        </div>
                        <div className="ContentSeller">
                            {selectedContent === 1 && <div>
                                <table>
                                    <thead>
                                        <tr id='zag'>
                                            <th id='ImageGlav'>Image</th>
                                            <th id='ProdGlav'>Product Name</th>
                                            <th id='StatusGlav'>Status</th>
                                            <th id='RevenueGlav'>Revenue</th>
                                            <th id='ConvertionGlav'>Conversion</th>
                                        </tr>
                                    </thead>
                                    <tbody id='Dlyaskrolla'>
                                        <tr key='row1' id='yach'>
                                            <td id='ImageContainer'><span><img src={Lyustrimg1} alt="" /></span></td>
                                            <td id='Naming'><p>Oneplus 9 Pro</p></td>
                                            <td id='Nalichnik'><div className="nalichi"><p>нет в наличии</p></div></td>
                                            <td id='Pricing'><p>$12.5k</p></td>
                                            <td id='Procenting'><div className="Sostoyanie"><p>+24%</p></div></td>
                                        </tr>
                                        <tr key='row1' id='yach'>
                                            <td id='ImageContainer'><span><img src={Lyustrimg1} alt="" /></span></td>
                                            <td id='Naming'><p>Oneplus 9 Pro</p></td>
                                            <td id='Nalichnik'><div className="nalichi"><p>нет в наличии</p></div></td>
                                            <td id='Pricing'><p>$12.5k</p></td>
                                            <td id='Procenting'><div className="Sostoyanie"><p>+24%</p></div></td>
                                        </tr>
                                        <tr key='row1' id='yach'>
                                            <td id='ImageContainer'><span><img src={Lyustrimg1} alt="" /></span></td>
                                            <td id='Naming'><p>Oneplus 9 Pro</p></td>
                                            <td id='Nalichnik'><div className="nalichi"><p>нет в наличии</p></div></td>
                                            <td id='Pricing'><p>$12.5k</p></td>
                                            <td id='Procenting'><div className="Sostoyanie"><p>+24%</p></div></td>
                                        </tr>
                                        <tr key='row1' id='yach'>
                                            <td id='ImageContainer'><span><img src={Lyustrimg1} alt="" /></span></td>
                                            <td id='Naming'><p>Oneplus 9 Pro</p></td>
                                            <td id='Nalichnik'><div className="nalichi"><p>нет в наличии</p></div></td>
                                            <td id='Pricing'><p>$12.5k</p></td>
                                            <td id='Procenting'><div className="Sostoyanie"><p>+24%</p></div></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>}
                            {selectedContent === 2 && <div>
                                <table>
                                    <thead>
                                        <tr id='zag'>
                                            <th id='ImageGlav'>Image</th>
                                            <th id='ProdGlav'>Product Name</th>
                                            <th id='StatusGlav'>Status</th>
                                            <th id='RevenueGlav'>Revenue</th>
                                            <th id='ConvertionGlav'>Conversion</th>
                                        </tr>
                                    </thead>
                                    <tbody id='Dlyaskrolla'>
                                        <tr key='row1' id='yach'>
                                            <td id='ImageContainer'><span><img src={Lyustrimg1} alt="" /></span></td>
                                            <td id='Naming'><p>Oneplus 9 Pro</p></td>
                                            <td id='Nalichnik'><div className="nalichi"><p>нет в наличии</p></div></td>
                                            <td id='Pricing'><p>$12.5k</p></td>
                                            <td id='Procenting'><div className="Sostoyanie"><p>+24%</p></div></td>
                                        </tr>
                                        <tr key='row1' id='yach'>
                                            <td id='ImageContainer'><span><img src={Lyustrimg1} alt="" /></span></td>
                                            <td id='Naming'><p>Oneplus 9 Pro</p></td>
                                            <td id='Nalichnik'><div className="nalichi"><p>нет в наличии</p></div></td>
                                            <td id='Pricing'><p>$12.5k</p></td>
                                            <td id='Procenting'><div className="Sostoyanie"><p>+24%</p></div></td>
                                        </tr>
                                        <tr key='row1' id='yach'>
                                            <td id='ImageContainer'><span><img src={Lyustrimg1} alt="" /></span></td>
                                            <td id='Naming'><p>Oneplus 9 Pro</p></td>
                                            <td id='Nalichnik'><div className="nalichi"><p>нет в наличии</p></div></td>
                                            <td id='Pricing'><p>$12.5k</p></td>
                                            <td id='Procenting'><div className="Sostoyanie"><p>+24%</p></div></td>
                                        </tr>
                                        <tr key='row1' id='yach'>
                                            <td id='ImageContainer'><span><img src={Lyustrimg1} alt="" /></span></td>
                                            <td id='Naming'><p>Oneplus 9 Pro</p></td>
                                            <td id='Nalichnik'><div className="nalichi"><p>нет в наличии</p></div></td>
                                            <td id='Pricing'><p>$12.5k</p></td>
                                            <td id='Procenting'><div className="Sostoyanie"><p>+24%</p></div></td>
                                        </tr>
                                    </tbody>
                                </table></div>}
                            {selectedContent === 3 && <div>Содержимое для элемента 3</div>}
                            {selectedContent === 4 && <div>Содержимое для элемента 4</div>}
                        </div>
                    </div>
                </div>
                <div className="UserContent">
                    <table>
                        <thead>
                            <tr id='USE'>
                                <th id='USE_User'>User</th>
                                <th id='USE_Email'>EMAIl</th>
                                <th id='USE_Role'>ROLE</th>
                                <th id='USE_Status'>STATUS</th>
                            </tr>
                        </thead>
                        <tbody id='DlyaskrollaUsera'>
                            <tr key='row1' id='UserYach'>
                                <td id='ImageContainer'><div className="USerDlyaScroll"><div className="USerDlyaScrollImg"><img src={useimg} alt="" /></div><div className="USerDlyaScrollName"><h4>Jordan Stevenson</h4><p>@amiccoo</p></div></div></td>
                                <td id='Email_User'><p>susanna.Lind57@gmail.com</p></td>
                                <td id='Role_User'><div className="RoleStat"><span><Icon path={mdiChandelier} size={1} /></span><p>Admin</p></div></td>
                                <td id='Status_User'><p>Pending</p></td>
                            </tr>
                            <tr key='row1' id='UserYach'>
                                <td id='ImageContainer'><div className="USerDlyaScroll"><div className="USerDlyaScrollImg"><img src={useimg} alt="" /></div><div className="USerDlyaScrollName"><h4>Jordan Stevenson</h4><p>@amiccoo</p></div></div></td>
                                <td id='Email_User'><p>susanna.Lind57@gmail.com</p></td>
                                <td id='Role_User'><div className="RoleStat"><span><Icon path={mdiChandelier} size={1} /></span><p>Admin</p></div></td>
                                <td id='Status_User'><p>Pending</p></td>
                            </tr>
                            <tr key='row1' id='UserYach'>
                                <td id='ImageContainer'><div className="USerDlyaScroll"><div className="USerDlyaScrollImg"><img src={useimg} alt="" /></div><div className="USerDlyaScrollName"><h4>Jordan Stevenson</h4><p>@amiccoo</p></div></div></td>
                                <td id='Email_User'><p>susanna.Lind57@gmail.com</p></td>
                                <td id='Role_User'><div className="RoleStat"><span><Icon path={mdiChandelier} size={1} /></span><p>Admin</p></div></td>
                                <td id='Status_User'><p>Pending</p></td>
                            </tr>
                            <tr key='row1' id='UserYach'>
                                <td id='ImageContainer'><div className="USerDlyaScroll"><div className="USerDlyaScrollImg"><img src={useimg} alt="" /></div><div className="USerDlyaScrollName"><h4>Jordan Stevenson</h4><p>@amiccoo</p></div></div></td>
                                <td id='Email_User'><p>susanna.Lind57@gmail.com</p></td>
                                <td id='Role_User'><div className="RoleStat"><span><Icon path={mdiChandelier} size={1} /></span><p>Admin</p></div></td>
                                <td id='Status_User'><p>Pending</p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div >
    )
}