 
import './../Category/Category.css'
import Rabotniki from './Rabotniki'

export default function RabEb() {
    return (
        <div div className="RabEb" >
            <Rabotniki/>
        </div>
    )
}