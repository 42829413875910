import './ProductList.css'
import Icon from '@mdi/react';
import { mdiChandelier, mdiCellphone, mdiTumbleDryer, mdiPercentBox } from '@mdi/js'
import { IoShareOutline } from "react-icons/io5";
import { FaChevronDown } from "react-icons/fa";
import { IoPrintOutline } from "react-icons/io5";
import { TbFileTypeCsv } from "react-icons/tb";
import { FaRegFilePdf } from "react-icons/fa6";
import { FaCopy } from "react-icons/fa";
import { useState, useEffect } from 'react';
import { BsThreeDotsVertical } from "react-icons/bs";
import { HiOutlinePencil } from "react-icons/hi2";
import Lyustr1 from './../../../Assets/Lyustr/pngwing(2).com.png'

export default function ProductList() {
    const [isActive, setIsActive] = useState(false);
    const [isActiveAction, setIsActiveAction] = useState({});
    const [checkboxStates, setCheckboxStates] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [rows, setRows] = useState([]);


    useEffect(() => {
        const fetchedRows = [
            {
                id: 1,
                imgSrc: Lyustr1,
                productName: 'Zoolab 1',
                productDesc: 'Treutel-Dickinson',
                categoryIcon: mdiChandelier,
                category: 'Household',
                stockChecked: true,
                sku: '64602',
                price: '$753.84',
                qty: '269',
                status: 'Scheduled'
            },
            {
                id: 2,
                imgSrc: Lyustr1,
                productName: 'Zoolab 2',
                productDesc: 'Treutel-Dickinson',
                categoryIcon: mdiChandelier,
                category: 'Household',
                stockChecked: true,
                sku: '64602',
                price: '$753.84',
                qty: '269',
                status: 'Scheduled'
            },
            {
                id: 3,
                imgSrc: Lyustr1,
                productName: 'Zoolab 3',
                productDesc: 'Treutel-Dickinson',
                categoryIcon: mdiChandelier,
                category: 'Household',
                stockChecked: true,
                sku: '64602',
                price: '$753.84',
                qty: '269',
                status: 'Scheduled'
            },
            {
                id: 4,
                imgSrc: Lyustr1,
                productName: 'Zoolab 4',
                productDesc: 'Treutel-Dickinson',
                categoryIcon: mdiChandelier,
                category: 'Household',
                stockChecked: true,
                sku: '64602',
                price: '$753.84',
                qty: '269',
                status: 'Scheduled'
            },
            {
                id: 5,
                imgSrc: Lyustr1,
                productName: 'Zoolab 5',
                productDesc: 'Treutel-Dickinson',
                categoryIcon: mdiChandelier,
                category: 'Household',
                stockChecked: true,
                sku: '64602',
                price: '$753.84',
                qty: '269',
                status: 'Scheduled'
            },
            {
                id: 6,
                imgSrc: Lyustr1,
                productName: 'Zoolab 6',
                productDesc: 'Treutel-Dickinson',
                categoryIcon: mdiChandelier,
                category: 'Household',
                stockChecked: true,
                sku: '64602',
                price: '$753.84',
                qty: '269',
                status: 'Scheduled'
            },
            {
                id: 7,
                imgSrc: Lyustr1,
                productName: 'Zoolab 7',
                productDesc: 'Treutel-Dickinson',
                categoryIcon: mdiChandelier,
                category: 'Household',
                stockChecked: true,
                sku: '64602',
                price: '$753.84',
                qty: '269',
                status: 'Scheduled'
            },
            {
                id: 8,
                imgSrc: Lyustr1,
                productName: 'Zoolab 8',
                productDesc: 'Treutel-Dickinson',
                categoryIcon: mdiChandelier,
                category: 'Household',
                stockChecked: true,
                sku: '64602',
                price: '$753.84',
                qty: '269',
                status: 'Scheduled'
            },
        ];
        setRows(fetchedRows);
    }, []);

    const toggleActive = () => {
        setIsActive(!isActive);
    };

    const toggleActiveAction = (index) => {
        setIsActiveAction(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    const handleToggleCheckbox = (index) => {
        setCheckboxStates(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    const goToPage = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const nextPage = () => {
        setCurrentPage(prevPage => prevPage + 1);
    };

    const prevPage = () => {
        setCurrentPage(prevPage => prevPage - 1);
    };

    const indexOfLastRow = currentPage * 6;
    const indexOfFirstRow = indexOfLastRow - 6;
    const currentRows = rows.slice(indexOfFirstRow, indexOfLastRow);

    const totalPages = Math.ceil(rows.length / 6);

    const paginationItems = [];
    if (totalPages <= 5) {
        for (let i = 1; i <= totalPages; i++) {
            paginationItems.push(
                <button id='Numbers' key={i} onClick={() => goToPage(i)} className={currentPage === i ? 'Numactive' : ''}>
                    {i}
                </button>
            );
        }
    } else {
        if (currentPage <= 3) {
            for (let i = 1; i <= 5; i++) {
                paginationItems.push(
                    <button id='Numbers' key={i} onClick={() => goToPage(i)} className={currentPage === i ? 'Numactive' : ''}>
                        {i}
                    </button>
                );
            }
            paginationItems.push(
                <span key="ellipsis">...</span>
            );
            paginationItems.push(
                <button key={totalPages} onClick={() => goToPage(totalPages)}>
                    {totalPages}
                </button>
            );
        } else if (currentPage >= totalPages - 2) {
            paginationItems.push(
                <button key={1} onClick={() => goToPage(1)}>
                    {1}
                </button>
            );
            paginationItems.push(
                <span key="ellipsis">...</span>
            );
            for (let i = totalPages - 4; i <= totalPages; i++) {
                paginationItems.push(
                    <button key={i} onClick={() => goToPage(i)} className={currentPage === i ? 'active' : ''}>
                        {i}
                    </button>
                );
            }
        } else {
            paginationItems.push(
                <button key={1} onClick={() => goToPage(1)}>
                    {1}
                </button>
            );
            paginationItems.push(
                <span key="ellipsis">...</span>
            );
            for (let i = currentPage - 1; i <= currentPage + 1; i++) {
                paginationItems.push(
                    <button key={i} onClick={() => goToPage(i)} className={currentPage === i ? 'active' : ''}>
                        {i}
                    </button>
                );
            }
            paginationItems.push(
                <span key="ellipsis">...</span>
            );
            paginationItems.push(
                <button key={totalPages} onClick={() => goToPage(totalPages)}>
                    {totalPages}
                </button>
            );
        }
    }

    return (
        <div className="ProductList">
            <div className="ProductListTitle">
                <h1>Product List</h1>
            </div>
            <div className="ProductListContent">
                <div className="ProductListBox">
                    <div className="ProductListBoxUp">
                        <div className="BoxUpLeft">
                            <p>Люстры</p>
                            <h4>$5,345.43</h4>
                            <p>5 тыс. заказов <span>+5.7%</span></p>
                        </div>
                        <div className="BoxUpRight">
                            <span><Icon id='IconProdList' path={mdiChandelier} size={1} /></span>
                        </div>
                    </div>
                </div>
                <div className="ProductListBox">
                    <div className="ProductListBoxUp">
                        <div className="BoxUpLeft">
                            <p>Телефоны</p>
                            <h4>$674,347.12</h4>
                            <p>21 тыс. заказов <span>+12,4%</span></p>
                        </div>
                        <div className="BoxUpRight">
                            <span><Icon id='IconProdList' path={mdiCellphone} size={1} /></span>
                        </div>
                    </div>
                </div>
                <div className="ProductListBox">
                    <div className="ProductListBoxUp">
                        <div className="BoxUpLeft">
                            <p>Бытовая Техника</p>
                            <h4>$14,235.12</h4>
                            <p>6 тысяч заказов</p>
                        </div>
                        <div className="BoxUpRight">
                            <span><Icon id='IconProdList' path={mdiTumbleDryer} size={1} /></span>
                        </div>
                    </div>
                </div>
                <div className="ProductListBox" id='LastProductListBox'>
                    <div className="ProductListBoxUp">
                        <div className="BoxUpLeft">
                            <p>Скидки</p>
                            <h4>$8,345.23 </h4>
                            <p>150 заказов<span id='minus'>-3.5%</span></p>
                        </div>
                        <div className="BoxUpRight">
                            <span><Icon id='IconProdList' path={mdiPercentBox} size={1} /></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ProductFilterContainer">
                <div className="ProductFilterTitle">
                    <h1>Filter</h1>
                </div>
                <div className="ProductFilterButtonBox">
                    <select>
                        <option value="Status">Status</option>
                        <option value="Scheduled">Scheduled</option>
                        <option value="Publish">Publish</option>
                        <option value="Inactive">Inactive</option>
                    </select>
                    <select>
                        <option value="Status">Category</option>
                        <option value="Scheduled">Household</option>
                        <option value="Publish">Electronics</option>
                        <option value="Inactive">Shoes</option>
                        <option value="Inactive">Accessories</option>
                        <option value="Inactive">Game</option>
                    </select>
                    <select>
                        <option value="Status">Stock</option>
                        <option value="Scheduled">Out Of Stock</option>
                        <option value="Publish">In Stock</option>
                    </select>
                </div>
                <div className="ProductFilterBottonChtoto">
                    <div className="ButtonChtotoUp">
                        <div className="ProdFilterSearch">
                            <input type="search" name="" id="" placeholder='Search Product' />
                        </div>
                        <div className="ProdFilterKnopki">
                            <select>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="40">40</option>
                                <option value="50">50</option>
                                <option value="60">60</option>
                                <option value="70">70</option>
                                <option value="80">80</option>
                                <option value="90">90</option>
                                <option value="100">100</option>

                            </select>
                            <div className={`ButtonExport ${isActive ? 'active' : ''}`} onClick={toggleActive}>
                                <button><span id='Exporticon'><IoShareOutline /></span>Export<span id='ExporticonDown'><FaChevronDown /></span></button>
                                <div className="ExportDeopDuwn">
                                    <button><span><IoPrintOutline /></span>Print</button>
                                    <button><span><TbFileTypeCsv /></span>Csv</button>
                                    <button><span><FaRegFilePdf /></span>Pdf</button>
                                    <button><span><FaCopy /></span>Copy</button>
                                </div>
                            </div>
                            <div className="AddProdButton">
                                <button>+ Add Product</button>
                            </div>
                        </div>
                    </div>
                    <div className="ButtonChtotoDuwn">
                        <table id='ChototTabe'>
                            <thead>
                                <tr id='ProdListGlav'>
                                    <th id='CheckboxProdList'><input type="checkbox" name="" id="" /></th>
                                    <th id='ProductProdList'>PRODUCT</th>
                                    <th id='CategoryProdList'>category</th>
                                    <th id='StockProdList'>stock</th>
                                    <th id='SkuProdList'>sku</th>
                                    <th id='PriceProdList'>price</th>
                                    <th id='QtyProdList'>qty</th>
                                    <th id='StatusProdList'>status</th>
                                    <th id='ActionsProdList'>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentRows.map((row, index) => (
                                    <tr id='PizdecPolniy' key={row.id}>
                                        <td id='CheckboxProdListContent'><input type="checkbox" /></td>
                                        <td id='ProductProdListContent'><div className="ProductListContant"><div className="ProductListContantImg"><img src={row.imgSrc} alt="" /></div><div className="ProductContantBoxText"><h4>{row.productName}</h4><p>{row.productDesc}</p></div></div></td>
                                        <td id='CategoryProdListContent'><div className="CategoriContant"><span><Icon path={row.categoryIcon} size={1} /></span><p>{row.category}</p></div> </td>
                                        <td id='StockProdListContent'><label className="toggle-switch"><input type="checkbox" checked={checkboxStates[index]} onChange={() => handleToggleCheckbox(index)} />  <span className="slider"></span> </label> </td>
                                        <td id='SkuProdListContent'><p>{row.sku}</p></td>
                                        <td id='PriceProdListContent'><p>{row.price}</p></td>
                                        <td id='QtyProdListContent'><p>{row.qty}</p></td>
                                        <td id='StatusProdListContent'><p>{row.status}</p></td>
                                        <td id='ActionsProdListContent'><div className="LastActionButtons"><button><HiOutlinePencil /></button><div className={`buttonActionGlav ${isActiveAction[index] ? 'active' : ''}`} onClick={() => toggleActiveAction(index)}><button><BsThreeDotsVertical /></button><div className="ActionDropDown"><button>View</button><button>Suspend</button></div></div></div></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="pagination">
                            <button id='Previos' onClick={prevPage} disabled={currentPage === 1}>
                                Previous
                            </button>
                            {paginationItems}
                            <button id='Next' onClick={nextPage} disabled={indexOfLastRow >= rows.length}>
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}