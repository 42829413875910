import './navbar.css';
import React, { useState, useEffect } from 'react';
import { HiMiniLanguage } from "react-icons/hi2";
import { MdOutlineNotifications } from "react-icons/md";
import { IoSearchSharp } from "react-icons/io5";
import ImgUser from './../../Assets/1680944641_pushinka-top-p-avatarki-dlya-shkolnogo-chata-avatarka-ins-16.jpg';
import { Link, useLocation } from 'react-router-dom';
import { FaRegUser } from "react-icons/fa";
import { MdOutlineSettings } from "react-icons/md";
import { IoLogOutOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';

export default function Navbar() {
    const [isActiveLang, setIsActiveLang] = useState(false);
    const [isActiveUved, setIsActiveUved] = useState(false);
    const [isActivated, setIsActivated] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [isFixed, setIsFixed] = useState(false);
    const [title, setTitle] = useState('Dashboard');

    const location = useLocation();

    useEffect(() => {
        switch (location.pathname) {
            case '/admin/Dashboard':
                setTitle('Главная');
                break;
            case '/admin/Category':
                setTitle('Продукты');
                break;
            case '/admin/Basic':
                setTitle('Работники');
                break;
            case '/admin/Profil':
                setTitle('Профиль')
                break;
            case '/user/Dashboarduser':
                setTitle('Главная')
                break;
            case '/user/Profil':
                setTitle('Профиль');
                break;
                case '/warehouse/Dashboard':
                    setTitle('Склад');
                    break;
            default:
                setTitle('My Application');
                break;
        }
    }, [location]);


    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('Выполняем поиск для запроса:', searchQuery);
    };

    const handleRecommendationClick = (text) => {
        setSearchQuery(text);
        setSearchResults([]);
        setIsActive(false);
    };

    const handleToggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleClick = () => {
        setIsActivated(!isActivated);
        setIsDropdownOpen(false);
    };
    const toggleActiveLang = () => {
        setIsActiveLang(!isActiveLang);
        setIsActiveUved(false);
    };

    const toggleActiveUved = () => {
        setIsActiveUved(!isActiveUved);
        setIsActiveLang(false);
    };

    const toggleActive = () => {
        setIsActive(!isActive);
    };

    const [activeButton, setActiveButton] = useState(null);

    const handleButtonClick = (buttonIndex) => {
        setActiveButton(buttonIndex);
    };

    const navigate = useNavigate();

    const handleLogout = () => {
        // Clear user-related data from localStorage
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('userRole');

        // Redirect to login page
        navigate('/');
    };

    const text = "Принято ваше подключение";

    return (
        <div className={`navbar ${isFixed ? 'fixed' : ''}`}>
            <div className='TitleNavbar' >
                <p>{title}</p>
            </div>
            <div className="Navmenu">
                <div className="navmenubutton">
                    <button className={`ButtonLang ${isActiveLang ? 'active' : ''}`} onClick={toggleActiveLang}>
                        <span><HiMiniLanguage /></span>
                        <div className="dropduwnLang">
                            <button><p>Ozbekcha</p></button>
                            <button><p>Русский</p></button>
                            <button><p>English</p></button>
                        </div>
                    </button>
                    <button className={`ButtonUved ${isActiveUved ? 'active' : ''}`} onClick={toggleActiveUved}>
                        <span><MdOutlineNotifications /></span>
                        <div className="dropduwnUved">
                            <div className="UvedTitle">
                                <p>Уведомление</p>
                                <span><p>8 новых</p></span>
                            </div>
                            <div className="UserUved">
                                <div className="UserUvedBox">
                                    <div className="UvedUserAvatars">
                                        <img src={ImgUser} alt="" />
                                    </div>
                                    <div className="UvedUserText">
                                        <h4>Чарльз Франклин</h4>
                                        <p className="truncate">{text}</p>
                                    </div>
                                    <div className="UvedUserTime">
                                        <p>12 часов назад</p>
                                    </div>
                                </div>
                                <div className="UserUvedBox">
                                    <div className="UvedUserAvatars">
                                        <img src={ImgUser} alt="" />
                                    </div>
                                    <div className="UvedUserText">
                                        <h4>Чарльз Франклин</h4>
                                        <p className="truncate">{text}</p>
                                    </div>
                                    <div className="UvedUserTime">
                                        <p>12 часов назад</p>
                                    </div>
                                </div>
                                <div className="UserUvedBox">
                                    <div className="UvedUserAvatars">
                                        <img src={ImgUser} alt="" />
                                    </div>
                                    <div className="UvedUserText">
                                        <h4>Чарльз Франклин</h4>
                                        <p className="truncate">{text}</p>
                                    </div>
                                    <div className="UvedUserTime">
                                        <p>12 часов назад</p>
                                    </div>
                                </div>
                                <div className="UserUvedBox">
                                    <div className="UvedUserAvatars">
                                        <img src={ImgUser} alt="" />
                                    </div>
                                    <div className="UvedUserText">
                                        <h4>Чарльз Франклин</h4>
                                        <p className="truncate">{text}</p>
                                    </div>
                                    <div className="UvedUserTime">
                                        <p>12 часов назад</p>
                                    </div>
                                </div>
                                <div className="UserUvedBox">
                                    <div className="UvedUserAvatars">
                                        <img src={ImgUser} alt="" />
                                    </div>
                                    <div className="UvedUserText">
                                        <h4>Чарльз Франклин</h4>
                                        <p className="truncate">{text}</p>
                                    </div>
                                    <div className="UvedUserTime">
                                        <p>12 часов назад</p>
                                    </div>
                                </div>
                                <div className="UserUvedBox">
                                    <div className="UvedUserAvatars">
                                        <img src={ImgUser} alt="" />
                                    </div>
                                    <div className="UvedUserText">
                                        <h4>Чарльз Франклин</h4>
                                        <p className="truncate">{text}</p>
                                    </div>
                                    <div className="UvedUserTime">
                                        <p>12 часов назад</p>
                                    </div>
                                </div>
                                <div className="UserUvedBox">
                                    <div className="UvedUserAvatars">
                                        <img src={ImgUser} alt="" />
                                    </div>
                                    <div className="UvedUserText">
                                        <h4>Чарльз Франклин</h4>
                                        <p className="truncate">{text}</p>
                                    </div>
                                    <div className="UvedUserTime">
                                        <p>12 часов назад</p>
                                    </div>
                                </div>
                            </div>
                            <div className="UvedAll">
                                <button>Просмотр всех уведомлений</button>
                            </div>
                        </div>
                    </button>
                </div>
                <div className={`NavUser ${isActivated ? 'active' : ''}`} onClick={handleClick}>
                    <button onClick={handleToggleDropdown}>
                        <img src={ImgUser} alt="" />
                    </button>
                    <div className="DropDuwnUserAva">
                        <div className="UserAvaInfo">
                            <div className="UserAvaInfoImg">
                                <img src={ImgUser} alt="" />
                            </div>
                            <div className="UserAvaInfoText">
                                <h4>Mukhamm Yunusov</h4>
                                <p>Admin</p>
                            </div>
                        </div>
                        <div className="UserAvaButton">
                            <Link to={'Profil'}><button><span><FaRegUser /></span><p>Мой профиль</p></button></Link>
                            <button><span><MdOutlineSettings /></span><p>Настройки</p></button>
                            <button onClick={handleLogout}>
                                <span><IoLogOutOutline /></span>
                                <p>Выход</p>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
