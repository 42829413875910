import './Profil.css';
import imgProf from './../../Assets/60906c1a-89ad-4a45-9abc-243a90496261.jpg';
import { IoLocationSharp, IoLanguageOutline, IoStarOutline } from "react-icons/io5";
import { FaCalendar, FaUser, FaTelegramPlane } from "react-icons/fa";
import { CiUser, CiFlag1, CiMail } from "react-icons/ci";
import { IoMdCheckmark } from "react-icons/io";
import { MdOutlinePhone } from "react-icons/md";
import Example from '../TableCategoryRab/TableCategory';
import axios from 'axios';
import { useEffect, useState } from 'react';

export default function Profil() {
    const [profileData, setProfileData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const token = localStorage.getItem("accessToken");

    useEffect(() => {
        async function GetProfile() {
            if (!token) {
                setError("Token not found");
                setLoading(false);
                return;
            }

            try {
                const response = await axios.get("https://api.posox.uz/api/profile/", {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log('API Response:', response.data);
                setProfileData(response.data);
            } catch (err) {
                console.error('Error fetching profile data:', err);
                setError("Failed to fetch profile data");
            } finally {
                setLoading(false);
            }
        }

        GetProfile();
    }, [token]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    if (!profileData) {
        return <div>No profile data available</div>;
    }

    return (
        <div className="Profil">
            <div className="ProfilTitle">
                <h1>Profil</h1>
            </div>
            <div className="ProfilNav">
                <div className="ProfilNavDown">
                    <div className="ProfilNavDownLeft">
                        <div className="ProfilNavDownContent">
                            <div className="ProfilLeftLeft">
                                <img src={imgProf} alt="" />
                            </div>
                            <div className="ProfilLeftRight">
                                <h1>{profileData.name}</h1>
                                <span>
                                    <p><FaUser /> {profileData.role}</p>
                                    <p><IoLocationSharp /> {profileData.location}</p>
                                    <p><FaCalendar /> {`Joined ${profileData.date_joined}`}</p>
                                </span>
                            </div>
                        </div>
                        <div className="ProfilNavDownRight">
                            <button>Подключено</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ProfilDown">
                <div className="ProfilContant">
                    <div className="ProfilContantTitle">
                        <p>Подробнее</p>
                    </div>
                    <div className="ProfilContantBox">
                        <span><h4><CiUser /> Ф.И.О:</h4><p>{profileData.first_name} {profileData.last_name}</p></span>
                        <span><h4><IoMdCheckmark /> Статус:</h4><p>{profileData.status}</p></span>
                        <span><h4><IoStarOutline /> Роль:</h4><p>{profileData.role}</p></span>
                        <span><h4><CiFlag1 /> Страна:</h4><p>{profileData.country}</p></span>
                        <span><h4><IoLanguageOutline /> Язык:</h4><p>{profileData.languages_known}</p></span>
                    </div>
                    <div className="ProfilContantTitle">
                        <p>Контакты</p>
                    </div>
                    <div className="ProfilContantBox">
                        <span><h4><MdOutlinePhone /> Контакты:</h4><p>{profileData.phone_number}</p></span>
                        <span><h4><FaTelegramPlane /> Telegram:</h4><p>{profileData.telegram}</p></span>
                        <span><h4><CiMail /> Mail:</h4><p>{profileData.email}</p></span>
                    </div>
                </div>
                <div className="ProfilTable">
                    <Example />
                </div>
            </div>
        </div>
    );
}
