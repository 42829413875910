import React, { useState, useEffect } from 'react';
import './Swiper.css';

const Swiper = ({ slides, autoPlayInterval = 5000 }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) =>
                prevIndex === slides.length - 1 ? 0 : prevIndex + 1
            );
        }, autoPlayInterval);
        return () => clearInterval(interval);
    }, [slides.length, autoPlayInterval]);

    const goToSlide = (index) => {
        setCurrentIndex(index);
    };

    return (
        <div className="swiper">
            <div
                className="swiper-slides"
                style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
                {slides.map((slide, index) => (
                    <div key={index} className="swiper-slide">
                        {slide}
                    </div>
                ))}
            </div>
            <div className="swiper-dots">
                {slides.map((_, index) => (
                    <div
                        key={index}
                        className={`swiper-dot ${index === currentIndex ? 'active' : ''}`}
                        onClick={() => goToSlide(index)}
                    ></div>
                ))}
            </div>
        </div>
    );
};

export default Swiper;
