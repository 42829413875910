import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Navbar from "./Components/Navbar/navbar";
import Leftmenu from "./Components/Leftmenu/Leftmenu";
import DashboardAdmin from "./Page/Admin/Dashboard/Dashboard";
import ProductList from "./Page/Admin/ProductList/ProductList";
import Ebeyshee from "./Page/Admin/Category/Ebeyshee";
import RabEb from "./Page/Admin/Rabotniki/EbeeysheeRab";
import Login from "./Components/Login/Login";
import DashboardUser from "./Page/User/Dashboard/Dashboard"; // New Warehouse Dashboard
import Profil from "./Components/Profil/Profil";
import Sclad from "./Page/Sclad/Sclad";
import ScladLeftmenu from "./Components/Leftmenu/ScladLeftmenu";
import ScladProducts from "./Page/Sclad/ProductSclad/ProductSclad";
import EbatBla from "./Page/Sclad/ProductSclad/EbatBla";

// PrivateRoute component for role-based routing
const PrivateRoute = ({ children, requiredRole }) => {
  const token = localStorage.getItem("accessToken");
  const role = localStorage.getItem("role");

  if (!token) {
    return <Navigate to="/" />;
  } 

  return children;
};

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Login />} />

          {/* Admin routes */}
          <Route
            path="/admin/*"
            element={
              <PrivateRoute requiredRole="Admin">
                <div className="LeftmenuSide">
                  <Leftmenu />
                </div>
                <div className="NavAndLeft">
                  <Navbar />
                  <Routes>
                    <Route path="Dashboard" element={<DashboardAdmin />} />
                    <Route path="Product-List" element={<ProductList />} />
                    <Route path="Category" element={<Ebeyshee />} />
                    <Route path="Basic" element={<RabEb />} />
                    <Route path="Profil" element={<Profil />} />
                  </Routes>
                </div>
              </PrivateRoute>
            }
          />

          {/* Warehouse routes */}
          <Route
            path="/warehouse/*"
            element={
              <PrivateRoute requiredRole="Warehouse">
                <div className="LeftmenuSide">
                  <ScladLeftmenu />
                </div>
                <div className="NavAndLeft">
                  <Navbar />
                  <Routes>
                    <Route path="Dashboard" element={<Sclad />} />
                    <Route path="ProductSclad" element={<EbatBla />} />
                    <Route path="Profil" element={<Profil />} />
                  </Routes>
                </div>
              </PrivateRoute>
            }
          />

          {/* User routes */}
          <Route
            path="/user/*"
            element={
              <PrivateRoute requiredRole="User">
                <div className="NavAndLeft">
                  <Navbar />
                  <Routes>
                    <Route path="Dashboarduser" element={<DashboardUser />} />
                    <Route path="Profil" element={<Profil />} />
                  </Routes>
                </div>
              </PrivateRoute>
            }
          />
 
        </Routes>
      </div>
    </Router>
  );
}

export default App;
