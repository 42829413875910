import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProductService } from "./service/ProductService";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Rating } from "primereact/rating";
import { Toolbar } from "primereact/toolbar";
import { InputTextarea } from "primereact/inputtextarea";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { RadioButton } from "primereact/radiobutton";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "../Category/Category.css";
import CustomModal from "./../../../Components/CastomModal/CustomModal.jsx";
import axios from "axios";
export default function ProductsDemo() {
  let emptyProduct = {
    id: null,
    name: "",
    image: null,
    description: "",
    category: null,
    price: 0,
    quantity: 0,
    rating: 0,
    inventoryStatus: "INSTOCK",
  };

  const [products, setProducts] = useState([]);
  const [productDialog, setProductDialog] = useState(false);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const [product, setProduct] = useState(emptyProduct);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const safeProducts = Array.isArray(products) ? products : [];
  const toast = useRef(null);
  const dt = useRef(null);
  const token = localStorage.getItem("accessToken"); 

  useEffect(() => {
    // ProductService.getProducts().then((data) => setProducts(data));
    GetProfile();
  }, []);

  async function GetProfile() {
    try {
      const response = await axios.get("https://api.posox.uz/api/profile/", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response.data); // Add this line to check the data structure
      setProducts(Array.isArray(response.data) ? response.data : []); // Ensure response data is an array
    } catch (err) {
      console.log(err);
    }
  }
  
  const formatCurrency = (value) => {
    if (value !== undefined && value !== null) {
      return value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    } else {
      return ""; // or return a default value, e.g., '$0.00'
    }
  };

  const openNew = () => {
    setProduct(emptyProduct);
    setSubmitted(false);
    setProductDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setProductDialog(false);
  };

  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };

  const hideDeleteProductsDialog = () => {
    setDeleteProductsDialog(false);
  };

  const saveProduct = () => {
    setSubmitted(true);

    if (product.name.trim()) {
      let _products = [...products];
      let _product = { ...product };

      if (product.id) {
        const index = findIndexById(product.id);

        _products[index] = _product;
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Product Updated",
          life: 3000,
        });
      } else {
        _product.id = createId();
        _product.image = "product-placeholder.svg";
        _products.push(_product);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Product Created",
          life: 3000,
        });
      }

      setProducts(_products);
      setProductDialog(false);
      setProduct(emptyProduct);
    }
  };

  const editProduct = (product) => {
    setProduct({ ...product });
    setProductDialog(true);
  };

  const confirmDeleteProduct = (product) => {
    setProduct(product);
    setDeleteProductDialog(true);
  };

  const deleteProduct = () => {
    let _products = products.filter((val) => val.id !== product.id);

    setProducts(_products);
    setDeleteProductDialog(false);
    setProduct(emptyProduct);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Product Deleted",
      life: 3000,
    });
  };

  const findIndexById = (id) => {
    let index = -1;

    for (let i = 0; i < products.length; i++) {
      if (products[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  };

  const createId = () => {
    let id = "";
    let chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 5; i++) {
      id += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    return id;
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const confirmDeleteSelected = () => {
    setDeleteProductsDialog(true);
  };

  const deleteSelectedProducts = () => {
    let _products = products.filter((val) => !selectedProducts.includes(val));

    setProducts(_products);
    setDeleteProductsDialog(false);
    setSelectedProducts(null);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Products Deleted",
      life: 3000,
    });
  };

  const onCategoryChange = (e) => {
    let _product = { ...product };

    _product["category"] = e.value;
    setProduct(_product);
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _product = { ...product };

    _product[`${name}`] = val;

    setProduct(_product);
  };

  const onInputNumberChange = (e, name) => {
    const val = e.value || 0;
    let _product = { ...product };

    _product[`${name}`] = val;

    setProduct(_product);
  };

  const openEditModal = () => {
    setModalVisible(true);
  };

  const closeEditModal = () => {
    setModalVisible(false);
  };

  const leftToolbarTemplate = () => {
    return (
      <div>
        <div id="buttoni-yebanie" className="flex flex-wrap gap-2">
          <Button
            label="New"
            icon="pi pi-plus"
            severity="success"
            onClick={openNew}
          />
          <Button
            label="View"
            severity="info"
            onClick={openEditModal}
            disabled={!selectedProducts || !selectedProducts.length}
          />
          <Button
            label="Delete"
            icon="pi pi-trash"
            severity="danger"
            onClick={confirmDeleteSelected}
            disabled={!selectedProducts || !selectedProducts.length}
          />
        </div>

        <CustomModal
          visible={modalVisible}
          onHide={closeEditModal}
          content={<div>Ваш кастомный контент для редактирования</div>}
        />
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <Button
        id="Expotr-Naxuy"
        label="Export"
        icon="pi pi-upload"
        className="p-button-help"
        onClick={exportCSV}
      />
    );
  };

  const imageBodyTemplate = (rowData) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          borderRadius: "0.5rem",
          overflow: "hidden",
        }}
      >
        <img
          src={`https://primefaces.org/cdn/primereact/images/product/${rowData.image}`}
          alt={rowData.image}
          className="shadow-2 border-round"
          style={{ width: "64px", height: "64px", objectFit: "cover" }}
        />
      </div>
    );
  };

  const priceBodyTemplate = (rowData) => {
    return formatCurrency(rowData.salary);
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <div
        className="tagbox"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Tag
          style={{
            display: "flex",
            justifyContent: "center",
            borderRadius: "0.5rem",
            overflow: "hidden",
          }}
          value={rowData.inventoryStatus}
          severity={getSeverity(rowData)}
        ></Tag>
      </div>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          style={{ color: "#666CFF" }}
          rounded
          outlined
          className="mr-2"
          onClick={() => editProduct(rowData)}
        />
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          severity="danger"
          onClick={() => confirmDeleteProduct(rowData)}
        />
      </React.Fragment>
    );
  };

  const getSeverity = (product) => {
    switch (product.inventoryStatus) {
      case "INSTOCK":
        return "success";

      case "LOWSTOCK":
        return "warning";

      case "OUTOFSTOCK":
        return "danger";

      default:
        return null;
    }
  };

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">Manage Products</h4>
      <IconField iconPosition="left">
        <InputIcon className="pi pi-search" />
        <InputText
          style={{ borderRadius: "0.5rem" }}
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </IconField>
    </div>
  );
  const productDialogFooter = (
    <React.Fragment>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
      <Button label="Save" icon="pi pi-check" onClick={saveProduct} />
    </React.Fragment>
  );
  const deleteProductDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteProductDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteProduct}
      />
    </React.Fragment>
  );
  const deleteProductsDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteProductsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteSelectedProducts}
      />
    </React.Fragment>
  );

  return (
    <div>
      <Toast ref={toast} />
      <div
        className="card"
        style={{ width: "100rem", display: "flex", flexDirection: "column" }}
      >
        <Toolbar
          className="mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        ></Toolbar>
        <DataTable
          ref={dt}
          value={products}
          selection={selectedProducts}
          onSelectionChange={(e) => setSelectedProducts(e.value)}
          dataKey="id"
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
          globalFilter={globalFilter}
          header={header}
          className="data-table" // Add this line
        >
          <Column
            selectionMode="multiple"
            style={{
              width: "12rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            exportable={false}
          ></Column>
          <Column
            field="image"
            header="ИЗОБРАЖЕНИЕ"
            style={{
              minWidth: "12rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            body={imageBodyTemplate}
          ></Column>
          <Column
            field="first_name"
            header="ИМЯ"
            sortable
            style={{
              width: "14rem",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          ></Column>
          <Column
            field="email"
            header="EMAIL"
            sortable
            style={{
              width: "15rem",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          ></Column>
          <Column
            field="status"
            header="СТАТУС"
            sortable
            style={{
              width: "12rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          ></Column>
          <Column
            field="phone_number"
            header="НОМЕР"
            sortable
            style={{
              width: "12rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          ></Column>
          <Column
            body={actionBodyTemplate}
            header="Контроль"
            exportable={false}
            style={{
              width: "12rem",
              display: "flex",
              justifyContent: "flex-start",
              gap: "10px",
              alignItems: "center",
            }}
          ></Column>
        </DataTable>
      </div>

      <Dialog
        visible={productDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Product Details"
        modal
        className="p-fluid"
        footer={productDialogFooter}
        onHide={hideDialog}
      >
        {product.image && (
          <img
            src={`https://primefaces.org/cdn/primereact/images/product/${product.image}`}
            alt={product.image}
            className="product-image block m-auto pb-3"
          />
        )}
        <div className="field">
          <label htmlFor="name" className="font-bold">
            Name
          </label>
          <InputText
            id="name"
            value={product.name}
            onChange={(e) => onInputChange(e, "name")}
            required
            autoFocus
            className={classNames({ "p-invalid": submitted && !product.name })}
          />
          {submitted && !product.name && (
            <small className="p-error">Name is required.</small>
          )}
        </div>
        <div className="formgrid grid">
          <div className="field">
            <label htmlFor="status" className="font-bold">
              status
            </label>
            <InputText
              id="status"
              value={product.status}
              onChange={(e) => onInputChange(e, "status")}
              required
              autoFocus
              className={classNames({
                "p-invalid": submitted && !product.status,
              })}
            />
            {submitted && !product.name && (
              <small className="p-error">Name is required.</small>
            )}
          </div>
          <div className="field">
            <label htmlFor="email" className="font-bold">
              email
            </label>
            <InputText
              id="email"
              value={product.email}
              onChange={(e) => onInputChange(e, "email")}
              required
              autoFocus
              className={classNames({
                "p-invalid": submitted && !product.email,
              })}
            />
            {submitted && !product.name && (
              <small className="p-error">Name is required.</small>
            )}
          </div>
          <div className="field col">
            <label htmlFor="salary" className="font-bold">
              salary
            </label>
            <InputNumber
              id="salary"
              value={product.salary}
              onValueChange={(e) => onInputNumberChange(e, "salary")}
              mode="currency"
              currency="USD"
              locale="en-US"
            />
          </div>
          <div className="field">
            <label htmlFor="Number" className="font-bold">
              Number
            </label>
            <InputText
              id="Number"
              value={product.Number}
              onChange={(e) => onInputChange(e, "Number")}
              required
              autoFocus
              className={classNames({
                "p-invalid": submitted && !product.Number,
              })}
            />
            {submitted && !product.Number && (
              <small className="p-error">Name is required.</small>
            )}
          </div>
        </div>
      </Dialog>

      <Dialog
        visible={deleteProductDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteProductDialogFooter}
        onHide={hideDeleteProductDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {product && (
            <span>
              Are you sure you want to delete <b>{product.name}</b>?
            </span>
          )}
        </div>
      </Dialog>

      <Dialog
        visible={deleteProductsDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteProductsDialogFooter}
        onHide={hideDeleteProductsDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {product && (
            <span>Are you sure you want to delete the selected products?</span>
          )}
        </div>
      </Dialog>
    </div>
  );
}
