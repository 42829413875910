import React from 'react';
import ReactApexChart from 'react-apexcharts';

class ApexChart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            series: [{
                name: 'XYZ MOTORS',
                data: props.dates
            }],
            options: {
                chart: {
                    type: 'line',
                    height: "100%",
                    zoom: {
                        enabled: false
                    },
                    toolbar: {
                        show: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                markers: {
                    size: 0
                },
                title: {
                    text: '',
                    align: 'left'
                },
                stroke: {
                    width: 4,
                    curve: 'smooth',
                    colors: ['#3b5998']  // Change line color here
                },
                grid: {
                    show: false
                },
                yaxis: {
                    show: false
                },
                xaxis: {
                    type: 'datetime',
                    labels: {
                        show: false
                    },
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    }
                },
                tooltip: {
                    enabled: false
                }
            }
        };
    }

    render() {
        return (
            <div id="chart">
                <ReactApexChart options={this.state.options} series={this.state.series} type="line" height={"90px"} />
            </div>
        );
    }
}

export default ApexChart;
