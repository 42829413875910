import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import './CustomModal.css';
import Example from './../TableCategory/TableCategory.jsx'

const CustomModal = ({ visible, onHide, product }) => {
    const renderCategoryTag = (category) => {
        const categories = {
            'Accessories': 'info',
            'Clothing': 'success',
            'Electronics': 'warning',
            'Fitness': 'danger',
        };
        return <Tag value={category} severity={categories[category] || 'secondary'} />;
    };

    return (
        <Dialog
            visible={visible}
            style={{ width: 'auto', borderRadius: '16px', backgroundColor: '#666CCF' }}
            header={<div className="modal-header">Product Details</div>}
            modal
            footer={
                <div className="modal-footer">
                    <Button label="Close" icon="pi pi-times" onClick={onHide} className="p-button-secondary" />
                </div>
            }
            onHide={onHide}
            className="custom-modal"
        >
            <div className="custom-modal-content">
                <div className="cutomModalTitle">
                    <h1>{product?.name || 'N/A'}</h1>
                </div>
                <Example />
            </div>
        </Dialog>
    );
};

export default CustomModal;
