import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { SlBasket } from "react-icons/sl";
import { FaChevronRight, FaCamera, FaTimes } from "react-icons/fa";
import { Html5Qrcode } from "html5-qrcode";
import "./Sclad.css";

export default function Sclad() {
  const [products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [cart, setCart] = useState([]);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const token = localStorage.getItem("accessToken");
  const [isEnabled, setEnabled] = useState(false);
  const [qrMessage, setQrMessage] = useState("");
  const [qrModalVisible, setQrModalVisible] = useState(false);
  const [qrProduct, setQrProduct] = useState(null); // Store product based on QR code
  
  const qrCodeContainerRef = useRef(null);

  useEffect(() => {
    setFilteredProducts(products);
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await axios.get("https://api.posox.uz/api/products/", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setProducts(response.data);
      setFilteredProducts(response.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const result = products.filter((product) =>
      product.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredProducts(result);
  }, [searchQuery, products]);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setSelectedProduct(null);
  };

  const handleAddToCart = (product) => {
    const existingProductIndex = cart.findIndex((item) => item.id === product.id);
    if (existingProductIndex !== -1) {
      const updatedCart = cart.map((item, index) =>
        index === existingProductIndex
          ? { ...item, quantity: item.quantity + 1 }
          : item
      );
      setCart(updatedCart);
    } else {
      setCart([...cart, { ...product, quantity: 1 }]);
    }
  };

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };

  const calculateTotal = () => {
    return cart
      .reduce((total, item) => total + item.price * item.quantity, 0)
      .toFixed(2);
  };

  const handleClearCart = () => {
    setCart([]);
  };

  const handleQuantityChange = (index, quantity) => {
    const maxQuantity = cart[index].rating; // Предполагаем, что rating хранит количество доступных товаров
    const updatedCart = cart.map((item, i) =>
      i === index ? { ...item, quantity: Math.min(Math.max(0, quantity), maxQuantity) } : item
    );
    setCart(updatedCart.filter((item) => item.quantity > 0));
  };


  const qrCodeError = (errorMessage) => {
    console.warn(`QR Code no longer in front of camera. Error message: ${errorMessage}`);
  };

  useEffect(() => {
    if (qrCodeContainerRef.current) {
      const html5QrCode = new Html5Qrcode(qrCodeContainerRef.current.id);

      const qrScannerStop = () => {
        if (html5QrCode.isScanning) {
          html5QrCode
            .stop()
            .then(() => {
              console.log("Scanner stopped");
            })
            .catch((err) => {
              console.error("Scanner stop error:", err);
            });
        }
      };

      const qrCodeSuccess = (decodedText) => {
        // Match the QR code content with a product
        const matchedProduct = products.find((product) => product.code === decodedText);
        setQrProduct(matchedProduct); // Set the product based on QR code
        setQrMessage(decodedText);
        setQrModalVisible(true);
        setEnabled(false);
      };

      if (isEnabled) {
        html5QrCode
          .start(
            { facingMode: "environment" },
            {
              fps: 10,
              qrbox: { width: 250, height: 250 },
            },
            qrCodeSuccess,
            qrCodeError
          )
          .catch((err) => {
            console.error("Scanner start error:", err);
          });
        setQrMessage("");
      } else {
        qrScannerStop();
      }

      return () => {
        qrScannerStop();
      };
    }
  }, [isEnabled, products]);

  const handleQrModalClose = () => {
    setQrModalVisible(false);
    setQrProduct(null);
  };

  const handleQrAddToCart = () => {
    if (qrProduct) {
      handleAddToCart(qrProduct);
    }
    setQrModalVisible(false);
  };

  const handleCloseScanner = () => {
    setEnabled(false);
  };

  return (
    <div className="DashboardUser">
      <div className="UserBoxSearch">
        <form onSubmit={(e) => e.preventDefault()}>
          <input
            type="search"
            value={searchQuery}
            onChange={handleSearch}
            placeholder="Поиск"
          />
          <button type="submit">Поиск</button>
        </form>
      </div>
      <div className="UserBoxContant">
        {filteredProducts.map((product) => (
          <div className="ContantBox" key={product.id}>
            <div className="userContantImgBox">
              <img src={product.image} alt={product.name} />
            </div>
            <div className="userContantTitleBox">
              <h4>{product.name}</h4>
              <p>${product.price}</p>
              <div className="UserCardContButton">
                <button onClick={() => handleAddToCart(product)}>
                  + Добавить
                </button>
                <button onClick={() => handleProductClick(product)}>
                  Подробнее
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div>
        <div className="openqrutton">
          <button onClick={() => setEnabled(!isEnabled)}>
            <FaCamera />
          </button>
        </div>
        {isEnabled && (
          <div className="scanner">
            <div
              id="qrCodeContainer"
              ref={qrCodeContainerRef}
            />
            <button className="close-scanner-button" onClick={handleCloseScanner}>
              <FaTimes /> Закрыть сканер
            </button>
          </div>
        )}
      </div>
      {qrModalVisible && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleQrModalClose}>
              &times;
            </span>
            <div className="modal-body">
              <h4>QR Code Content</h4>
              <p>{qrMessage}</p>
              <div className="modal-buttons">
                <button onClick={handleQrAddToCart}>Добавить</button>
                <button onClick={handleQrModalClose}>Закрыть</button>
              </div>
            </div>
          </div>
        </div>
      )}
      {modalVisible && selectedProduct && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleCloseModal}>
              &times;
            </span>
            <div className="modal-body">
              <h4>{selectedProduct.name}</h4>
              <img src={selectedProduct.image} alt={selectedProduct.name} />
              <div className="ProductDiscription">
                <p>Цена: ${selectedProduct.price}</p>
                <p>Поступлено: {selectedProduct.code}</p>
                <p>Остаток: {selectedProduct.rating}</p>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="Korzina">
        <button className="KorzButton" onClick={toggleCart}>
          <SlBasket />
        </button>
        <div className={`CartDrawer ${isCartOpen ? "open" : ""}`}>
          <div className="CartContent">
            <div className="CloseButton">
              <button onClick={toggleCart}>
                <FaChevronRight />
              </button>
            </div>
            {cart.length > 0 ? (
              <>
                {cart.map((item, index) => (
                  <div key={index} className="CartItem">
                    <div className="CartItemImgBox">
                      <img src={item.image} alt={item.name} />
                    </div>
                    <div className="CartItemText">
                      <h4>
                        {item.name} {item.id}
                      </h4>
                      <p>${item.price}</p>
                    </div>
                    <div className="CartItemInput">
                      <input
                        type="number"
                        value={item.quantity}
                        onChange={(e) =>
                          handleQuantityChange(index, parseInt(e.target.value))
                        }
                        min="1"
                      />
                    </div>
                  </div>
                ))}
                <div className="CartTotal">
                  <h4>Итого:</h4>
                  <p>${calculateTotal()}</p>
                </div>
              </>
            ) : (
              <p>Корзина пуста</p>
            )}
            <div className="SendButtonCartItem">
              <button onClick={handleClearCart}>Сбросить</button>
              <button>Отправить</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
