import './Leftmenu.css'
import { FaAngleDoubleRight } from "react-icons/fa";
import { FaAngleDoubleLeft } from "react-icons/fa";
import { GoDotFill } from "react-icons/go";
import { FaChevronRight } from "react-icons/fa";
import { useState } from 'react';
import { MdDashboardCustomize } from "react-icons/md";
import { MdIosShare } from "react-icons/md";
import { IoReorderFour } from "react-icons/io5";
import { RiCustomerService2Fill } from "react-icons/ri";
import { MdOutlineSettings } from "react-icons/md";
import { Link } from 'react-router-dom';


export default function ScladLeftmenu() {

    const [activeButtons, setActiveButtons] = useState({
        products: false,
        order: false,
        customer: false,
        settings: false
    });

    const handleButtonClick = (buttonName) => {
        const newActiveButtons = {
            dashboard: buttonName === 'dashboard' ? !activeButtons.dashboard : false,
            products: buttonName === 'products' ? !activeButtons.products : false,
            order: buttonName === 'order' ? !activeButtons.order : false,
            customer: buttonName === 'customer' ? !activeButtons.customer : false,
            settings: buttonName === 'settings' ? !activeButtons.settings : false
        };
        setActiveButtons(newActiveButtons);
    };
    const [isActive, setIsActive] = useState(false);

    const handleClick = () => {
        setIsActive(!isActive);
    };

    return (
        <div className={`LeftMenuContainer ${isActive ? 'active' : ''}`}>
            <div className={`Leftmenu ${isActive ? 'active' : ''}`}>
                <Link to={'Dashboard'}>
                    <div className="LeftMenuLogo">
                        <h1 className='OneQ'>Q</h1>
                        <h1 className='TwoQ'>QuickHub</h1>
                    </div>
                </Link>
                <div className="Menubutton"> 
                    <Link to={'ProductSclad'}><button className={`menu-button ${activeButtons.products ? 'active' : ''}`} onClick={() => handleButtonClick('products')}>
                        <div className="ButtonContant">
                            <div className="buttoncont">
                                <span><MdIosShare /></span>
                                <p>Products</p>
                            </div>
                        </div>
                    </button>
                    </Link> 
                    <button className={`menu-button ${activeButtons.settings ? 'active' : ''}`} onClick={() => handleButtonClick('settings')}>
                        <div className="ButtonContant">
                            <div className="buttoncont">
                                <span><MdOutlineSettings /></span>
                                <p>Settings</p>
                            </div>
                        </div>
                    </button>
                    <button id='ButtonAside' className="ButtonAside" onClick={handleClick}>
                        <span className='RightAngel'><FaAngleDoubleRight /></span>
                        <span id='LeftAngel' className='LeftAngel'><FaAngleDoubleLeft /></span>
                    </button>
                </div>
            </div>
        </div >
    )
}