import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProductService } from "./service/ProductService";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Rating } from "primereact/rating";
import { Toolbar } from "primereact/toolbar";
import { InputTextarea } from "primereact/inputtextarea";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { RadioButton } from "primereact/radiobutton";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import QRCodeCanvas from "qrcode.react";
import CustomModal from "./../../../Components/CastomModalTovar/CustomModal";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "./Category.css";
import axios from "axios";

export default function ProductsDemo() {
  let emptyProduct = {
    id: null,
    name: "",
    image: null,
    description: "",
    category: null,
    price: 0,
    quantity: 0,
    rating: 0,
    inventoryStatus: "INSTOCK",
  };

  const [products, setProducts] = useState([]);
  const [productDialog, setProductDialog] = useState(false);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const [product, setProduct] = useState(emptyProduct);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedProductForModal, setSelectedProductForModal] = useState(null); // New state for selected product
  const toast = useRef(null);
  const dt = useRef(null);
  const token = localStorage.getItem("accessToken");

  useEffect(() => {
    // ProductService.getProducts().then((data) => setProducts(data));
    GetProduct();
  }, []);

  async function GetProduct() {
    try {
      const response = await axios.get("https://api.posox.uz/api/products/", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setProducts(response.data);
    } catch (err) {
      console.log(err);
    }
  }
  console.log(products);

  const formatCurrency = (value) => {
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };

  const openNew = () => {
    setProduct(emptyProduct);
    setSubmitted(false);
    setProductDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setProductDialog(false);
  };

  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };

  const hideDeleteProductsDialog = () => {
    setDeleteProductsDialog(false);
  };

  const saveProduct = () => {
    setSubmitted(true);

    if (product.name.trim()) {
      let _products = [...products];
      let _product = { ...product };

      if (product.id) {
        const index = findIndexById(product.id);

        _products[index] = _product;
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Product Updated",
          life: 3000,
        });
      } else {
        _product.id = createId();
        _product.image = "product-placeholder.svg";
        _products.push(_product);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Product Created",
          life: 3000,
        });
      }

      setProducts(_products);
      setProductDialog(false);
      setProduct(emptyProduct);
    }
  };

  const editProduct = (product) => {
    setProduct({ ...product });
    setProductDialog(true);
  };

  const confirmDeleteProduct = (product) => {
    setProduct(product);
    setDeleteProductDialog(true);
  };

  const deleteProduct = () => {
    let _products = products.filter((val) => val.id !== product.id);

    setProducts(_products);
    setDeleteProductDialog(false);
    setProduct(emptyProduct);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Product Deleted",
      life: 3000,
    });
  };

  const openEditModal = () => {
    if (selectedProducts.length === 1) {
      setSelectedProductForModal(selectedProducts[0]);
      setModalVisible(true);
    }
  };

  const closeEditModal = () => {
    setModalVisible(false);
  };

  const findIndexById = (id) => {
    return products.findIndex((product) => product.id === id);
  };

  const createId = () => {
    return Math.random().toString(36).substring(2, 7).toUpperCase();
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const confirmDeleteSelected = () => {
    setDeleteProductsDialog(true);
  };

  const deleteSelectedProducts = () => {
    let _products = products.filter((val) => !selectedProducts.includes(val));

    setProducts(_products);
    setDeleteProductsDialog(false);
    setSelectedProducts([]);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Products Deleted",
      life: 3000,
    });
  };

  const onCategoryChange = (e) => {
    let _product = { ...product };
    _product["category"] = e.value;
    setProduct(_product);
  };

  const onInputChange = (e, name) => {
    const val = e.target.value || "";
    let _product = { ...product };
    _product[name] = val;
    setProduct(_product);
  };

  const onInputNumberChange = (e, name) => {
    const val = e.value || 0;
    let _product = { ...product };
    _product[name] = val;
    setProduct(_product);
  };

  const leftToolbarTemplate = () => {
    return (
      <div>
        <div id="buttoni-yebanie" className="flex flex-wrap gap-2">
          <Button
            label="New"
            icon="pi pi-plus"
            severity="success"
            onClick={openNew}
          />
          <Button
            label="View"
            icon="pi pi"
            severity="info"
            onClick={openEditModal}
            disabled={selectedProducts.length !== 1}
          />
          <Button
            label="Delete"
            icon="pi pi-trash"
            severity="danger"
            onClick={confirmDeleteSelected}
            disabled={!selectedProducts.length}
          />
        </div>
        <CustomModal
          visible={modalVisible}
          onHide={closeEditModal}
          product={selectedProductForModal}
        />
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <Button
        id="Expotr-Naxuy"
        label="Export"
        icon="pi pi-upload"
        className="p-button-help"
        onClick={exportCSV}
      />
    );
  };

  const imageBodyTemplate = (rowData) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          borderRadius: "0.5rem",
          overflow: "hidden",
        }}
      >
        <img
          src={`https://primefaces.org/cdn/primereact/images/product/${rowData.image}`}
          alt={rowData.image}
          className="shadow-2 border-round"
          style={{ width: "64px", height: "64px", objectFit: "cover" }}
        />
      </div>
    );
  };

  const qrCodeBodyTemplate = (rowData) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <QRCodeCanvas
          value={`${rowData.name}`}
          size={64}
        />
      </div>
    );
  };

  const priceBodyTemplate = (rowData) => {
    return formatCurrency(rowData.price);
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <div
        className="tagbox"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Tag
          style={{
            display: "flex",
            justifyContent: "center",
            borderRadius: "0.5rem",
            overflow: "hidden",
          }}
          value={rowData.inventoryStatus}
          severity={getSeverity(rowData)}
        ></Tag>
      </div>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          style={{ color: "#666CFF" }}
          rounded
          outlined
          className="mr-2"
          onClick={() => editProduct(rowData)}
        />
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          severity="danger"
          onClick={() => confirmDeleteProduct(rowData)}
        />
      </React.Fragment>
    );
  };

  const getSeverity = (product) => {
    switch (product.inventoryStatus) {
      case "INSTOCK":
        return "success";

      case "LOWSTOCK":
        return "warning";

      case "OUTOFSTOCK":
        return "danger";

      default:
        return null;
    }
  };

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">Manage Products</h4>
      <IconField iconPosition="left">
        <InputIcon className="pi pi-search" />
        <InputText
          style={{ borderRadius: "0.5rem" }}
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </IconField>
    </div>
  );
  const productDialogFooter = (
    <React.Fragment>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
      <Button label="Save" icon="pi pi-check" onClick={saveProduct} />
    </React.Fragment>
  );
  const deleteProductDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteProductDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteProduct}
      />
    </React.Fragment>
  );
  const deleteProductsDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteProductsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteSelectedProducts}
      />
    </React.Fragment>
  );

  return (
    <div>
      <Toast ref={toast} />
      <div
        className="card"
        style={{ width: "100rem", display: "flex", flexDirection: "column" }}
      >
        <Toolbar
          className="mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        ></Toolbar>
        <DataTable
          ref={dt}
          value={products}
          selection={selectedProducts}
          onSelectionChange={(e) => setSelectedProducts(e.value)}
          dataKey="id"
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
          globalFilter={globalFilter}
          header={header}
          className="data-table"
        >
          <Column
            selectionMode="multiple"
            style={{
              width: "12rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            exportable={false}
          ></Column>
          <Column
            field="quantity"
            header="Начальный Остаток"
            sortable
            style={{
              width: "19rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          ></Column>
          <Column
            field="sold_quantity"
            header="Продажа"
            sortable
            style={{
              width: "12rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          ></Column>
          <Column
            field="name"
            header="Модель"
            sortable
            style={{
              width: "15rem",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          ></Column>
          <Column
            field="price"
            header="Цена $"
            body={priceBodyTemplate}
            sortable
            style={{
              width: "12rem",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          ></Column>
          <Column
            field="uzs_price"
            header="Цена UZS"
            sortable
            style={{
              width: "12rem",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          ></Column>
          <Column
            field="profit"
            header="Прибыль"
            sortable
            style={{
              width: "12rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          ></Column>
          <Column
            field="sold_quantity"
            header="Остаток"
            sortable
            style={{
              width: "13rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          ></Column>
          <Column
            field="cost_price"
            header="Себестоимость"
            sortable
            style={{
              width: "12rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          ></Column>
          <Column
            field="qrcode"
            header="QR Code"
            body={qrCodeBodyTemplate}
            style={{
              width: "12rem",
              display: "flex",
              justifyContent: "center",
            }}
          ></Column>
          <Column
            body={actionBodyTemplate}
            header="Контроль"
            exportable={false}
            style={{
              width: "8rem",
              display: "flex",
              justifyContent: "flex-start",
              gap: "10px",
              alignItems: "center",
            }}
          ></Column>
        </DataTable>
      </div>

      <Dialog
        visible={productDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Product Details"
        modal
        className="p-fluid"
        footer={productDialogFooter}
        onHide={hideDialog}
      >
        {product.image && (
          <img
            src={`https://primefaces.org/cdn/primereact/images/product/${product.image}`}
            alt={product.image}
            className="product-image block m-auto pb-3"
          />
        )}
        <div className="field">
          <label htmlFor="name" className="font-bold">
            Модель
          </label>
          <InputText
            id="name"
            value={product.name}
            onChange={(e) => onInputChange(e, "name")}
            required
            autoFocus
            className={classNames({ "p-invalid": submitted && !product.name })}
          />
          {submitted && !product.name && (
            <small className="p-error">Name is required.</small>
          )}
        </div>
        <div className="formgrid grid">
          <div className="field col">
            <label htmlFor="Sebestoimost" className="font-bold">
              Себестоимость
            </label>
            <InputNumber
              id="Sebestoimost"
              value={product.Sebestoimost}
              onChange={(e) => onInputNumberChange(e, "Sebestoimost")}
              required
              autoFocus
              className={classNames({
                "p-invalid": submitted && !product.Sebestoimost,
              })}
              mode="currency"
              currency="USD"
              locale="en-US"
            />
            {submitted && !product.Sebestoimost && (
              <small className="p-error">Name is required.</small>
            )}
          </div>
          <div className="field col">
            <label htmlFor="price" className="font-bold">
              Цена$
            </label>
            <InputNumber
              id="price"
              value={product.price}
              onValueChange={(e) => onInputNumberChange(e, "price")}
              mode="currency"
              currency="USD"
              locale="en-US"
            />
          </div>
          <div className="field col">
            <label htmlFor="uzsprice" className="font-bold">
              Цена сум
            </label>
            <InputNumber
              id="uzsprice"
              value={product.uzsprice}
              onChange={(e) => onInputNumberChange(e, "uzsprice")}
              required
              autoFocus
              className={classNames({
                "p-invalid": submitted && !product.uzsprice,
              })}
            />
            {submitted && !product.uzsprice && (
              <small className="p-error">Name is required.</small>
            )}
          </div>
          <div className="field col">
            <label htmlFor="code" className="font-bold">
              Начальный остаток
            </label>
            <InputNumber
              id="code"
              value={product.code}
              onValueChange={(e) => onInputNumberChange(e, "code")}
            />
          </div>
        </div>
      </Dialog>

      <Dialog
        visible={deleteProductDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteProductDialogFooter}
        onHide={hideDeleteProductDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {product && (
            <span>
              Are you sure you want to delete <b>{product.name}</b>?
            </span>
          )}
        </div>
      </Dialog>

      <Dialog
        visible={deleteProductsDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteProductsDialogFooter}
        onHide={hideDeleteProductsDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {product && (
            <span>Are you sure you want to delete the selected products?</span>
          )}
        </div>
      </Dialog>
    </div>
  );
}
