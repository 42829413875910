import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./login.css";
import { FaUser } from "react-icons/fa";
import { RiLockPasswordFill } from "react-icons/ri";
import axios from "axios";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [ese, setEse] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    const isStaff = localStorage.getItem("is_staff");

    if (token && isStaff !== null) {
      if (isStaff === "true") {
        navigate("/admin/Dashboard");
      } else {
        navigate("/user/Dashboarduser");
      }
    }
 
    if (token) {
      const intervalId = setInterval(() => {
        refreshAccessToken();
      }, 4 * 60 * 1000);  

      return () => clearInterval(intervalId);
    }
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    console.log("Logging in with", { username, password });

    try {
      const response = await axios.post("https://api.posox.uz/api/token/", {
        username,
        password,
      });

      const { access, refresh, is_staff } = response.data;
      console.log(response.data);

      localStorage.setItem("accessToken", access);
      localStorage.setItem("refreshToken", refresh);
      localStorage.setItem("is_staff", is_staff);

      if (is_staff) {
        navigate("/admin/Dashboard");
      } else {
        navigate("/user/Dashboarduser");
      }
    } catch (error) {
      console.error(
        "Login error",
        error.response ? error.response.data : error.message
      );
      if (error.response && error.response.status === 401) {
        alert("Invalid login credentials");
      }
    }
  };

  const refreshAccessToken = async () => {
    const refreshToken = localStorage.getItem("refreshToken");
    if (!refreshToken) return;

    try {
      const response = await axios.post(
        "https://api.posox.uz/api/token/refresh/",
        {
          refresh: refreshToken,
        }
      );

      const { access } = response.data;
      localStorage.setItem("accessToken", access);
      console.log("Access token refreshed");
    } catch (error) {
      console.error(
        "Token refresh error",
        error.response ? error.response.data : error.message
      );
      if (error.response && error.response.status === 401) {
        alert("Session expired. Please log in again.");
        navigate("/");
      }
    }
  };

  return (
    <div className="login_container">
      <div className="screen">
        <div className="screen__content">
          <form className="login" onSubmit={handleLogin}>
            <div className="login__field">
              <span>
                <FaUser />
              </span>
              <input
                type="text"
                className="login__input"
                placeholder="Ismingiz"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="login__field">
              <span>
                <RiLockPasswordFill />
              </span>
              <input
                type={ese ? "text" : "password"}
                className="login__input"
                placeholder="Parol"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button onClick={() => setEse(!ese)} type="button">
                e
              </button>
            </div>
            <button className="button login__submit" type="submit">
              <span className="button__text">Log In Now</span>
              <i className="button__icon fas fa-chevron-right"></i>
            </button>
          </form>
        </div>
        <div className="screen__background">
          <span className="screen__background__shape screen__background__shape4"></span>
          <span className="screen__background__shape screen__background__shape3"></span>
          <span className="screen__background__shape screen__background__shape2"></span>
          <span className="screen__background__shape screen__background__shape1"></span>
        </div>
      </div>
    </div>
  );
};

export default Login;
