export type Person = {
    id: number;
    DataOperation: String;
    Name: String;
    Shtuk: String;
    sumd: String;
    sums: String;
    Ostatok: String;
    bilo: String;
    Prodavec: String;
  };
  
  export const data = [
      {
          id: 1,
          DataOperation: '24.07.2024',
          Name: '6084',
          Shtuk: '2',
          sumd: '54$',
          sums: '540.000',
          Ostatok: '8',
          bilo: '10',
          Prodavec: 'Yunusov Mukhammad',
      },
      {
          id: 2,
          DataOperation: '24.07.2024',
          Name: '6084',
          Shtuk: '2',
          sumd: '54$',
          sums: '540.000',
          Ostatok: '8',
          bilo: '10',
          Prodavec: 'Yunusov Mukhammad'
      },
      {
          id: 3,
          DataOperation: '24.07.2024',
          Name: '6084',
          Shtuk: '2',
          sumd: '54$',
          sums: '540.000',
          Ostatok: '8',
          bilo: '10',
          Prodavec: 'Yunusov Mukhammad'
      },
      {
          id: 4,
          DataOperation: '24.07.2024',
          Name: '6084',
          Shtuk: '2',
          sumd: '54$',
          sums: '540.000',
          Ostatok: '8',
          bilo: '10',
          Prodavec: 'Yunusov Mukhammad'
      },
      {
          id: 5,
          DataOperation: '24.07.2024',
          Name: '6084',
          Shtuk: '2',
          sumd: '54$',
          sums: '540.000',
          Ostatok: '8',
          bilo: '10',
          Prodavec: 'Yunusov Mukhammad'
      },
      {
          id: 6,
          DataOperation: '24.07.2024',
          Name: '6084',
          Shtuk: '2',
          sumd: '54$',
          sums: '540.000',
          Ostatok: '8',
          bilo: '10',
          Prodavec: 'Yunusov Mukhammad'
      },
      {
          id: 7,
          DataOperation: '24.07.2024',
          Name: '6084',
          Shtuk: '2',
          sumd: '54$',
          sums: '540.000',
          Ostatok: '8',
          bilo: '10',
          Prodavec: 'Yunusov Mukhammad'
      },
      {
          id: 8,
          DataOperation: '24.07.2024',
          Name: '6084',
          Shtuk: '2',
          sumd: '54$',
          sums: '540.000',
          Ostatok: '8',
          bilo: '10',
          Prodavec: 'Yunusov Mukhammad'
      },
      {
          id: 9,
          DataOperation: '24.07.2024',
          Name: '6084',
          Shtuk: '2',
          sumd: '54$',
          sums: '540.000',
          Ostatok: '8',
          bilo: '10',
          Prodavec: 'Yunusov Mukhammad'
      },
      {
          id: 10,
          DataOperation: '24.07.2024',
          Name: '6084',
          Shtuk: '2',
          sumd: '54$',
          sums: '540.000',
          Ostatok: '8',
          bilo: '10',
          Prodavec: 'Yunusov Mukhammad'
      },
      {
          id: 11,
          DataOperation: '24.07.2024',
          Name: '6084',
          Shtuk: '2',
          sumd: '54$',
          sums: '540.000',
          Ostatok: '8',
          bilo: '10',
          Prodavec: 'Yunusov Mukhammad'
      },
      {
          id: 12,
          DataOperation: '24.07.2024',
          Name: '6084',
          Shtuk: '2',
          sumd: '54$',
          sums: '540.000',
          Ostatok: '8',
          bilo: '10',
          Prodavec: 'Yunusov Mukhammad'
      },
  ] as unknown as Person[];
  