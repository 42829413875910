import React from 'react';
import { MaterialReactTable } from 'material-react-table';
import { Box, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { generateCsv, mkConfig } from 'export-to-csv'; // updated import
import { data } from './makeData.tsx';

// defining columns outside of the component is fine, is stable
const columns = [
    {
        accessorKey: 'id',
        header: 'ID',
        size: 40,
    },
    {
        accessorKey: 'Prodavec',
        header: 'Продовец',
        size: 220,
    },
    {
        accessorKey: 'DataOperation',
        header: 'Дата операции',
        size: 120,
    },

    {
        accessorKey: 'Name',
        header: 'Модель',
        size: 120,
    },
    {
        accessorKey: 'Shtuk',
        header: 'Продано',
        size: 150,
    },
    {
        accessorKey: 'sumd',
        header: 'Сумма $',
    },
    {
        accessorKey: 'Ostatok',
        header: 'Остаток',
        size: 220,
    },
    {
        accessorKey: 'sums',
        header: 'Сумма',
        size: 220,
    },
    {
        accessorKey: 'bilo',
        header: 'Начальный остаток',
        size: 220,
    },

];

const csvOptions = mkConfig({
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
});

const Example = () => {
    const handleExportRows = (rows) => {
        const csvData = rows.map((row) => row.original);
        const csvString = generateCsv(csvData, csvOptions);
        const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'data.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleExportData = () => {
        const csvString = generateCsv(data, csvOptions);
        const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'data.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <MaterialReactTable
            columns={columns}
            data={data}
            enableRowSelection
            positionToolbarAlertBanner="bottom"
            renderTopToolbarCustomActions={({ table }) => (
                <Box
                    sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                >
                    <Button
                        style={{ backgroundColor: '#666CFF' }}
                        color='primary'
                        onClick={handleExportData}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        Export All Data
                    </Button>
                    <Button
                        style={{ backgroundColor: '#666CFF' }}
                        disabled={table.getPrePaginationRowModel().rows.length === 0}
                        onClick={() =>
                            handleExportRows(table.getPrePaginationRowModel().rows)
                        }
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        Export All Rows
                    </Button>
                    <Button
                        style={{ backgroundColor: '#666CFF' }}
                        disabled={table.getRowModel().rows.length === 0}
                        onClick={() => handleExportRows(table.getRowModel().rows)}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        Export Page Rows
                    </Button>
                    <Button
                        style={{ backgroundColor: '#666CFF' }}
                        disabled={
                            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                        }
                        onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        Export Selected Rows
                    </Button>
                </Box>
            )}
        />
    );
};

export default Example;
